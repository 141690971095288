<div class="quick-filter">
  <button  class="btn dropdown-toggle align-items-center filter-head"
    data-bs-toggle="collapse"
    data-bs-target="#quick-filter">
    Quick filters
  </button>

  <div class="collapse show" id="quick-filter">
    <ul class="filter-ui">
      @for (item of items; track $index) {
        <li>
          <a [class.active]="item.isActive" (click)="requestQuickFilter(item)">{{item.label}}</a>
        </li>
      }
    </ul>
  </div>
</div>
