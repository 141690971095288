import { Component, EventEmitter, Input, Output, input } from '@angular/core';
import { QuickFilterItem } from '../../../../core/modals/table.modal';

@Component({
  selector: 'app-quick-filters',
  standalone: true,
  imports: [],
  templateUrl: './quick-filters.component.html',
  styleUrl: './quick-filters.component.scss',
})
export class QuickFiltersComponent {
  @Input() items: QuickFilterItem[];

  @Output() quickFilterRequest = new EventEmitter();

  requestQuickFilter(selectedItem: QuickFilterItem): void {
    this.items.forEach((item) => {
      item.isActive = item === selectedItem;
    });
    this.quickFilterRequest.emit();
  }
}
